<template>
  <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Promoções">
      <crud-base
        :url="url"
        :columns="columns"
        :onDelete="() => {}"
        :onUpdate="() => {}"
        :onStore="() => {}"
        :enableSearch="true"
        :formInputs="formInputs"
        :checkAlerts="true"
        primaryKey="id"
      />
    </CTab>
  </CTabs>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {
  schemaBooleanSelect, schemaDatePicker,
  schemaMoney,
  schemaNumber, schemaSelect,
  schemaTextField
} from "@/helpers/form";
import {yesNoRepresentation} from "@/helpers/transformers";
import {monetary} from "@/filters/monetary";
import {dateFilter} from "@/filters/dateFilter";
export default {
  name: "promocoes",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/precos-promocionais",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Descrição", field: "descricao", sortable: false },
        { label: "Cód. prod", field: "produto_id", sortable: false },
        { label: "Cód. filial", field: "filial_id", sortable: false },
        { label: "Dt. Início", field: "dtinicio", sortable: false, representedAs: (row) => dateFilter(row.dtinicio) },
        { label: "Dt. Fim", field: "dtfim", sortable: false, representedAs: (row) => dateFilter(row.dtfim) },
        { label: "Preço", field: "precobase", sortable: false, representedAs: (row) => monetary(row.precobase) },
        { label: "Ativo", field: "precobase", sortable: false, representedAs: (row) => yesNoRepresentation(row.ativo) },
      ],
      formInputs: {
        id: schemaTextField('Cód.', true, 2),
        ativo: schemaBooleanSelect('Ativo', true, 2, true, 1),
        aplicacallcenter: schemaBooleanSelect('Exibe Call?', true, 2, true, 1),
        aplicab2b: schemaBooleanSelect('Exibe B2B?', true, 2, true, 1),
        aplicafv: schemaBooleanSelect('Exibe FV?', true, 2, true, 1),
        precobase: schemaMoney('Preço Base', true, 2),
        descricao: schemaTextField('Descrição', false, 3),

        "_line1": {
          "type": "HTML",
          "value": "<strong>Restrições de ativação</strong><hr/>"
        },
        produto_id: schemaTextField('Cód. Produto', true, 3),
        filial_id: schemaTextField('Cód. Filial', false, 3),
        regiao_id: schemaTextField('Cód. Região', false, 3),
        praca_id: schemaTextField('Cód. Praça', false, 3),
        rede_id: schemaTextField('Cód. Rede', false, 3),
        atividade_id: schemaTextField('Cód. Atividade', false, 3),
        cliente_id: schemaTextField('Cód. Cliente', false, 3),
        supervisor_id: schemaTextField('Cód. Superv.', false, 3),
        representante_id: schemaTextField('Cód. RCA', false, 3),

        "_line2": {
          "type": "HTML",
          "value": "<strong>Regras gerais</strong><hr/>"
        },
        permitefinanceiro: schemaBooleanSelect('Aplica Financeiro?', true, 3, true, 0),
        precofinal: schemaBooleanSelect('É preço final?', true, 3, true, 0),
        dtinicio: schemaDatePicker('Dt. início', true, 3),
        dtfim: schemaDatePicker('Dt. fim', true, 3),

        planopagamento_id_max: schemaTextField('Cód. Plano (prazo máx)', false, 3),
        apenasplano_max: schemaBooleanSelect('Apenas Plano Máximo?', false, 3, true, 0),
        aplica_desconto: schemaBooleanSelect('Aplica Descontos?', false, 3, true, 0),

        visivel: schemaBooleanSelect('Visível ao cliente?', false, 3, true, 1),
        tabelapreco: schemaTextField('Tabela de Preço', false, 3),
        coluna_preco: schemaSelect('Num. Col. preço', false, 3, {
          null : "Todas",
          '1' : "Preço 1",
          '2' : "Preço 2",
          '3' : "Preço 3",
          '4' : "Preço 4",
          '5' : "Preço 5",
          '6' : "Preço 6",
          '7' : "Preço 7"
        }, null),
        qtdmin: schemaNumber('Qtd. Mínima', true, 3, 0),
        qtdmax: schemaNumber('Qtd. Máxima', false, 3)
      },
    };
  },
};
</script>
